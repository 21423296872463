import * as cons from './constants';
import { simpleReducerUi } from '../helpers/helpers';

// reducers return state, previous state is also store in reducers
// it’s just a function that takes state and action as arguments, and returns the next state of the app
// the reducer must be pure. Given the same arguments, it should calculate the next state and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.

const appLanguage = (state = { language: localStorage.getItem('lang') || 'zh-Hant' }, action) => simpleReducerUi(cons.APP_LANGUAGE, state, action);
const defaultSalesOrderData = (state = {}, action) => simpleReducerUi(cons.SET_DEFAULT_SALES_ORDER_DATA, state, action);
const defaultServiceMemoData = (state = {}, action) => simpleReducerUi(cons.SET_DEFAULT_SERVICE_MEMO_DATA, state, action);
const defaultShopOrderData = (state = {}, action) => simpleReducerUi(cons.SET_DEFAULT_SHOP_ORDER_DATA, state, action);
const stockCheckItemCode = (state = {}, action) => simpleReducerUi(cons.SET_STOCK_CHECK_ITEM_CODE, state, action);

export default {
	appLanguage,
	defaultSalesOrderData,
	defaultServiceMemoData,
	defaultShopOrderData,
	stockCheckItemCode,
};
