import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
	faReceipt,
	faStickyNote,
	faFileAlt,
	faChartBar,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import Spinner from 'Components/spinner';
import MenuBarButton from './components/MenuBarButton.jsx';
import styles from './MenuBar.less';
import loc from '../../components/languages/index.js';

class MenuBar extends PureComponent {
	constructor() {
		super();
		this.menu = [
			{
				id: 'salesOrder',
				icon: faReceipt,
				href: 'sales-order',
				permission: 'shop_front_sales_order',
			},
			{
				id: 'serviceMemo',
				icon: faStickyNote,
				href: 'service-memo',
				permission: 'shop_front_service_memo',
			},
			{
				id: 'orderSearch',
				icon: faFileAlt,
				href: 'order/search',
				permission: 'shop_front_order_search',
			},
			{
				id: 'report',
				icon: faChartBar,
				href: 'report',
				permission: 'shop_front_report',
			},
		];
	}

	onClickHandler(link) {
		this.props.history.push(`/app/${link}`);
	}

	render() {
		const { currentInfo } = this.props;
		return (
			<nav id="menuBar" className={ classnames('uk-width-small', styles.menuBar, process.env.ENVIRONMENT !== 'PROD' && styles.uat) }>
				<Link to="/app/home">
					<img src="/assets/images/logo.png" className={ styles.logo } />
				</Link>
				{
					process.env.ENVIRONMENT !== 'PROD' && (
						<div className={ styles.uatLabel }>{ process.env.ENVIRONMENT }</div>
					)
				}
				{
					currentInfo.isFetching ? (
						<Spinner />
					) : (
						this.menu.map((menuItem, index) => (
							(!menuItem.permission || !(currentInfo && currentInfo.data && currentInfo.data.permissions && currentInfo.data.permissions[menuItem.permission] === ' ')) && (
								<MenuBarButton
									key={ `menu-bar-button-${index}` }
									icon={ menuItem.icon }
									label={ loc[menuItem.id] }
									href={ menuItem.href }
									className={ styles.button }
								/>
							)
						))
					)
				}
			</nav>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		currentInfo: state.currentInfo,
	})
)(MenuBar));