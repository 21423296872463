import { constants_maker, constants_ui_maker } from '../helpers/helpers';

// create all action types by constructing an object for each action

// constants-ui
export const APP_LANGUAGE = constants_ui_maker('APP_LANGUAGE');
export const SET_DEFAULT_SALES_ORDER_DATA = constants_ui_maker('SET_DEFAULT_SALES_ORDER_DATA');
export const SET_DEFAULT_SERVICE_MEMO_DATA = constants_ui_maker('SET_DEFAULT_SERVICE_MEMO_DATA');
export const SET_DEFAULT_SHOP_ORDER_DATA = constants_ui_maker('SET_DEFAULT_SHOP_ORDER_DATA');
export const SET_STOCK_CHECK_ITEM_CODE = constants_ui_maker('SET_STOCK_CHECK_ITEM_CODE');
export const RESET_ERROR = constants_ui_maker('RESET_ERROR');

// Copied from puyi-pos-native
export const POST_LOGIN = constants_maker('POST_LOGIN');
export const GET_CURRENT_USER = constants_maker('GET_CURRENT_USER');
export const REFRESH_ACCESS_TOKEN = constants_maker('REFRESH_ACCESS_TOKEN');
export const GET_SALESMAN = constants_maker('GET_SALESMAN');
export const UPDATE_SALESORDER = constants_maker('UPDATE_SALESORDER');
export const CONFIRM_SALESORDER = constants_maker('CONFIRM_SALESORDER');
export const HOLD_SALESORDER = constants_maker('HOLD_SALESORDER');
export const RETRIEVE_HOLD_SALESORDER = constants_maker('RETRIEVE_HOLD_SALESORDER');
export const RETRIEVE_HOLD_SERVICEMEMO = constants_maker('RETRIEVE_HOLD_SERVICEMEMO');
export const UPDATE_SERVICEMEMO = constants_maker('UPDATE_SERVICEMEMO');
export const CONFIRM_SERVICEMEMO = constants_maker('CONFIRM_SERVICEMEMO');
export const HOLD_SERVICEMEMO = constants_maker('HOLD_SERVICEMEMO');
export const UPDATE_SHOPORDER = constants_maker('UPDATE_SHOPORDER');
export const CONFIRM_SHOPORDER = constants_maker('CONFIRM_SHOPORDER');
export const HOLD_SHOPORDER = constants_maker('HOLD_SHOPORDER');
export const RETRIEVE_HOLD_SHOPORDER = constants_maker('RETRIEVE_HOLD_SHOPORDER');
export const GET_MEMBER = constants_maker('GET_MEMBER');
export const GET_MEMBER_DETAILS = constants_maker('GET_MEMBER_DETAILS');
export const GET_ITEM = constants_maker('GET_ITEM');
export const BATCH_GET_ITEMS = constants_maker('BATCH_GET_ITEMS');
export const GET_FAVORITE_ITEMS = constants_maker('GET_FAVORITE_ITEMS');
export const SET_ITEM_TO_CURRENT_SHOP = constants_maker('SET_ITEM_TO_CURRENT_SHOP');
export const UNSET_ITEM_FROM_CURRENT_SHOP = constants_maker('UNSET_ITEM_FROM_CURRENT_SHOP');
export const GET_STOCK = constants_maker('GET_STOCK');
export const GET_SERVICE_ITEMS = constants_maker('GET_SERVICE_ITEMS');
export const GET_PAYMENTS = constants_maker('GET_PAYMENTS');
export const GET_FILTER_SELECTIONS = constants_maker('GET_FILTER_SELECTIONS');
export const GET_REMARK = constants_maker('GET_REMARK');
export const GET_SALES_REMARK_TEMPLATES = constants_maker('GET_SALES_REMARK_TEMPLATES');
export const GET_SALES_REMARK_TEMPLATE_DETAILS = constants_maker('GET_SALES_REMARK_TEMPLATE_DETAILS');
export const CREATE_SALES_REMARK_TEMPLATE = constants_maker('CREATE_SALES_REMARK_TEMPLATE');
export const UPDATE_SALES_REMARK_TEMPLATE = constants_maker('UPDATE_SALES_REMARK_TEMPLATE');
export const GET_SALES_REMARK_TEMPLATE_OPTIONS = constants_maker('GET_SALES_REMARK_TEMPLATE_OPTIONS');
export const GET_EXCHANGE_RATES = constants_maker('GET_EXCHANGE_RATES');
export const GET_PMS_FILTERS = constants_maker('GET_PMS_FILTERS');
export const GET_PMS_FILTER_OPTIONS = constants_maker('GET_PMS_FILTER_OPTIONS');
export const GET_OPMS_FILTERS = constants_maker('GET_OPMS_FILTERS');
export const GET_OPMS_FILTER_OPTIONS = constants_maker('GET_OPMS_FILTER_OPTIONS');
export const GET_LOT_ITEM = constants_maker('GET_LOT_ITEM');
export const GET_LOTNO = constants_maker('GET_LOTNO');
export const GET_TRANSACTIONS = constants_maker('GET_TRANSACTIONS');
export const CONVERT_Q_To_R = constants_maker('CONVERT_Q_To_R');
export const CONVERT_Q_To_R_SERVICEMEMO = constants_maker('CONVERT_Q_To_R_SERVICEMEMO');
export const DEPOSIT_REFUND = constants_maker('DEPOSIT_REFUND');
export const DEPOSIT_REFUND_SERVICEMEMO = constants_maker('DEPOSIT_REFUND_SERVICEMEMO');
export const VOID_ORDER = constants_maker('VOID_ORDER');
export const GET_RETURN_ITEM = constants_maker('GET_RETURN_ITEM');
export const POST_APPROVAL = constants_maker('POST_APPROVAL');
export const CHANGE_PASSWORD = constants_maker('CHANGE_PASSWORD');
export const PREVIEW_ORDER = constants_maker('PREVIEW_ORDER');
export const VIEW_ORDER = constants_maker('VIEW_ORDER');
export const PRINT_ORDER = constants_maker('PRINT_ORDER');
export const GET_TRANSFER_IN_ORDER = constants_maker('GET_TRANSFER_IN_ORDER');
export const GET_TRANSFER_OUT_ORDER = constants_maker('GET_TRANSFER_OUT_ORDER');
export const GET_TRANSFER_DETAILS = constants_maker('GET_TRANSFER_DETAILS');
export const PRINT_TRANSFER_ORDER = constants_maker('PRINT_TRANSFER_ORDER');
export const GET_WAREHOUSE = constants_maker('GET_WAREHOUSE');
export const GET_WAREHOUSE_BY_FILTER = constants_maker('GET_WAREHOUSE_BY_FILTER');
export const SET_USER_CURRENTSHOP = constants_maker('SET_USER_CURRENTSHOP');
export const UNSET_USER_CURRENTSHOP = constants_maker('UNSET_USER_CURRENTSHOP');
export const SET_FAVORITE_MEMBER = constants_maker('SET_FAVORITE_MEMBER');
export const UNSET_FAVORITE_MEMBER = constants_maker('UNSET_FAVORITE_MEMBER');
export const CONFIRM_TRANSFER_IN = constants_maker('CONFIRM_TRANSFER_IN');
export const CREATE_TRANSFER_OUT = constants_maker('CREATE_TRANSFER_OUT');
export const CONFIRM_CASHMOVEMENT = constants_maker('CONFIRM_CASHMOVEMENT');
export const GET_CASHMOVEMENT_RECORDS = constants_maker('GET_CASHMOVEMENT_RECORDS');
export const GET_CASHMOVEMENT_DETAILS = constants_maker('GET_CASHMOVEMENT_DETAILS');
export const CHANGE_WAREHOUSE = constants_maker('CHANGE_WAREHOUSE');
export const FIND_GIFT_COUPON_BY_CAMERA = constants_maker('FIND_GIFT_COUPON_BY_CAMERA');
export const FIND_GIFT_COUPON_BY_INPUT = constants_maker('FIND_GIFT_COUPON_BY_INPUT');
export const FIND_CASH_COUPON_BY_INPUT = constants_maker('FIND_CASH_COUPON_BY_INPUT');
export const GET_REPORTS = constants_maker('GET_REPORTS');
export const GET_REPORT_DETAILS = constants_maker('GET_REPORT_DETAILS');
export const GET_REPORT_DETAILS_ASYNC = constants_maker('GET_REPORT_DETAILS_ASYNC');
export const GET_REPORT_FILTERS = constants_maker('GET_REPORT_FILTERS');
export const GET_REPORT_OPTIONS = constants_maker('GET_REPORT_OPTIONS');
export const GET_REPORT_EXPORT = constants_maker('GET_REPORT_EXPORT');
export const GET_RFT_INFO = constants_maker('GET_RFT_INFO');
export const GET_RFT_INFO_DETAILS = constants_maker('GET_RFT_INFO_DETAILS');
export const GET_SPECIAL_OPTIONS = constants_maker('GET_SPECIAL_OPTIONS');
export const GET_CUSTOMER_ANLYS = constants_maker('GET_CUSTOMER_ANLYS');

// clear cache
export const POST_LOGOUT = constants_maker('POST_LOGOUT');
export const CLEAR_CACHE_SALESORDER = constants_maker('CLEAR_CACHE_SALESORDER');
export const CLEAR_CACHE_SERVICEMEMO = constants_maker('CLEAR_CACHE_SERVICEMEMO');
export const CLEAR_CACHE_SHOPORDER = constants_maker('CLEAR_CACHE_SHOPORDER');
