import * as cons from './constants';
import { simpleInitialState, simpleReducer, multiReducer } from '../helpers/helpers';
import { List } from 'immutable';

// reducers return state, previous state is also store in reducers
// it’s just a function that takes state and action as arguments, and returns the next state of the app
// the reducer must be pure. Given the same arguments, it should calculate the next state and return it. No surprises. No side effects. No API calls. No mutations. Just a calculation.

const loginInfoInitialState = localStorage.getItem('user') ? {
	data: JSON.parse(localStorage.getItem('user')),
	dataFetched: true,
	isFetching: false,
	error: false,
	timestamp: Date.now(),
	type: cons.POST_LOGIN.SUCCESS,
} : simpleInitialState;

const loginInfo = (state = loginInfoInitialState, action) => multiReducer([cons.POST_LOGIN, cons.REFRESH_ACCESS_TOKEN], state, action);
const errorInfo = (state = new List(), action) => {
	if (action.type === cons.RESET_ERROR.REQUEST_UPDATE) {
		state = new List();
	} else {
		for (const con in cons) {
			switch (con) {
			case 'POST_APPROVAL':
				break;
			default:
				if (action.type == cons[con].FAILURE) {
					state = state.push(action);
				}
				break;
			}
		}
	}
	return state;
};

// Copied from puyi-pos-native
const currentInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_CURRENT_USER, state, action);
const salesmanInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_SALESMAN, state, action);
const salesOrderInfo = (state = simpleInitialState, action) => multiReducer([cons.UPDATE_SALESORDER, cons.CONVERT_Q_To_R, cons.DEPOSIT_REFUND, cons.CLEAR_CACHE_SALESORDER], state, action);
const salesOrderConfirmInfo = (state = simpleInitialState, action) => simpleReducer(cons.CONFIRM_SALESORDER, state, action);
const salesOrderHoldInfo = (state = simpleInitialState, action) => simpleReducer(cons.HOLD_SALESORDER, state, action);
const retrieveHoldSalesOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.RETRIEVE_HOLD_SALESORDER, state, action);
const retrieveHoldServiceMemoInfo = (state = simpleInitialState, action) => simpleReducer(cons.RETRIEVE_HOLD_SERVICEMEMO, state, action);
const serviceMemoInfo = (state = simpleInitialState, action) => multiReducer([cons.UPDATE_SERVICEMEMO, cons.CONVERT_Q_To_R_SERVICEMEMO, cons.DEPOSIT_REFUND_SERVICEMEMO, cons.CLEAR_CACHE_SERVICEMEMO], state, action);
const serviceMemoConfirmInfo = (state = simpleInitialState, action) => simpleReducer(cons.CONFIRM_SERVICEMEMO, state, action);
const serviceMemoHoldInfo = (state = simpleInitialState, action) => simpleReducer(cons.HOLD_SERVICEMEMO, state, action);
const shopOrderInfo = (state = simpleInitialState, action) => multiReducer([cons.UPDATE_SHOPORDER, cons.CLEAR_CACHE_SHOPORDER], state, action);
const shopOrderConfirmInfo = (state = simpleInitialState, action) => simpleReducer(cons.CONFIRM_SHOPORDER, state, action);
const shopOrderHoldInfo = (state = simpleInitialState, action) => simpleReducer(cons.HOLD_SHOPORDER, state, action);
const retrieveHoldShopOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.RETRIEVE_HOLD_SHOPORDER, state, action);
const memberInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_MEMBER, state, action);
const memberDetailsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_MEMBER_DETAILS, state, action);
const itemInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_ITEM, state, action);
const itemsInfo = (state = simpleInitialState, action) => simpleReducer(cons.BATCH_GET_ITEMS, state, action);
const favoriteItemsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_FAVORITE_ITEMS, state, action);
const setItemToCurrentShopInfo = (state = simpleInitialState, action) => simpleReducer(cons.SET_ITEM_TO_CURRENT_SHOP, state, action);
const unsetItemFromCurrentShopInfo = (state = simpleInitialState, action) => simpleReducer(cons.UNSET_ITEM_FROM_CURRENT_SHOP, state, action);
const stockInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_STOCK, state, action);
const serviceItemsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_SERVICE_ITEMS, state, action);
const paymentsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_PAYMENTS, state, action);
const remarkInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REMARK, state, action);
const salesRemarkTemplatesInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_SALES_REMARK_TEMPLATES, state, action);
const salesRemarkTemplateDetailsInfo = (state = simpleInitialState, action) => multiReducer([cons.GET_SALES_REMARK_TEMPLATE_DETAILS, cons.CREATE_SALES_REMARK_TEMPLATE, cons.UPDATE_SALES_REMARK_TEMPLATE], state, action);
const salesRemarkTemplateOptionsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_SALES_REMARK_TEMPLATE_OPTIONS, state, action);
const exchangeRatesInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_EXCHANGE_RATES, state, action);
const pmsFilterInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_PMS_FILTERS, state, action);
const pmsFilterOptionsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_PMS_FILTER_OPTIONS, state, action);
const opmsFilterInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_OPMS_FILTERS, state, action);
const opmsFilterOptionsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_OPMS_FILTER_OPTIONS, state, action);
const lotItemInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_LOT_ITEM, state, action);
const lotNoInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_LOTNO, state, action);
const transactionsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_TRANSACTIONS, state, action);
const approvalInfo = (state = simpleInitialState, action) => simpleReducer(cons.POST_APPROVAL, state, action);
const voidOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.VOID_ORDER, state, action);
const returnItemInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_RETURN_ITEM, state, action);
const changePasswordInfo = (state = simpleInitialState, action) => simpleReducer(cons.CHANGE_PASSWORD, state, action);
const previewOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.PREVIEW_ORDER, state, action);
const viewOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.VIEW_ORDER, state, action);
const printOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.PRINT_ORDER, state, action);
const transferInOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_TRANSFER_IN_ORDER, state, action);
const transferOutOrderInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_TRANSFER_OUT_ORDER, state, action);
const transferDetailsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_TRANSFER_DETAILS, state, action);
const transferPdfInfo = (state = simpleInitialState, action) => simpleReducer(cons.PRINT_TRANSFER_ORDER, state, action);
const warehouseInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_WAREHOUSE, state, action);
const warehouseByFilterInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_WAREHOUSE_BY_FILTER, state, action);
const setCurrentShopUserInfo = (state = simpleInitialState, action) => simpleReducer(cons.SET_USER_CURRENTSHOP, state, action);
const unsetCurrentShopUserInfo = (state = simpleInitialState, action) => simpleReducer(cons.UNSET_USER_CURRENTSHOP, state, action);
const setFavoriteMemberInfo = (state = simpleInitialState, action) => simpleReducer(cons.SET_FAVORITE_MEMBER, state, action);
const unsetFavoriteMemberInfo = (state = simpleInitialState, action) => simpleReducer(cons.UNSET_FAVORITE_MEMBER, state, action);
const transferInOrderConfirmInfo = (state = simpleInitialState, action) => simpleReducer(cons.CONFIRM_TRANSFER_IN, state, action);
const transferOutOrderCreateInfo = (state = simpleInitialState, action) => simpleReducer(cons.CREATE_TRANSFER_OUT, state, action);
const cashMovementConfirmInfo = (state = simpleInitialState, action) => simpleReducer(cons.CONFIRM_CASHMOVEMENT, state, action);
const cashMovementRecordsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_CASHMOVEMENT_RECORDS, state, action);
const cashMovementDetailsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_CASHMOVEMENT_DETAILS, state, action);
const changeWarehouseInfo = (state = simpleInitialState, action) => simpleReducer(cons.CHANGE_WAREHOUSE, state, action);
const giftCameraCouponInfo = (state = simpleInitialState, action) => simpleReducer(cons.FIND_GIFT_COUPON_BY_CAMERA, state, action);
const giftCouponInfo = (state = simpleInitialState, action) => simpleReducer(cons.FIND_GIFT_COUPON_BY_INPUT, state, action);
const cashCouponInfo = (state = simpleInitialState, action) => simpleReducer(cons.FIND_CASH_COUPON_BY_INPUT, state, action);
const reportInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REPORTS, state, action);
const reportDetailsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REPORT_DETAILS, state, action);
const reportJobInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REPORT_DETAILS_ASYNC, state, action);
const reportFilterInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REPORT_FILTERS, state, action);
const reportOptionInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REPORT_OPTIONS, state, action);
const reportExportInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_REPORT_EXPORT, state, action);
const rftInfoInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_RFT_INFO, state, action);
const rftInfoDetailsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_RFT_INFO_DETAILS, state, action);
const specialOptionsInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_SPECIAL_OPTIONS, state, action);
const customerAnlysInfo = (state = simpleInitialState, action) => simpleReducer(cons.GET_CUSTOMER_ANLYS, state, action);
const postLogoutInfo = (state = simpleInitialState, action) => simpleReducer(cons.POST_LOGOUT, state, action);

export default {
	errorInfo,
	// Copied from puyi-pos-native
	loginInfo,
	currentInfo,
	salesmanInfo,
	salesOrderInfo,
	salesOrderConfirmInfo,
	salesOrderHoldInfo,
	retrieveHoldSalesOrderInfo,
	retrieveHoldServiceMemoInfo,
	serviceMemoInfo,
	serviceMemoConfirmInfo,
	serviceMemoHoldInfo,
	shopOrderInfo,
	shopOrderConfirmInfo,
	shopOrderHoldInfo,
	retrieveHoldShopOrderInfo,
	memberInfo,
	memberDetailsInfo,
	itemInfo,
	itemsInfo,
	favoriteItemsInfo,
	setItemToCurrentShopInfo,
	unsetItemFromCurrentShopInfo,
	stockInfo,
	serviceItemsInfo,
	paymentsInfo,
	remarkInfo,
	salesRemarkTemplatesInfo,
	salesRemarkTemplateDetailsInfo,
	salesRemarkTemplateOptionsInfo,
	exchangeRatesInfo,
	pmsFilterInfo,
	pmsFilterOptionsInfo,
	opmsFilterInfo,
	opmsFilterOptionsInfo,
	lotItemInfo,
	lotNoInfo,
	transactionsInfo,
	approvalInfo,
	voidOrderInfo,
	returnItemInfo,
	changePasswordInfo,
	previewOrderInfo,
	viewOrderInfo,
	printOrderInfo,
	transferInOrderInfo,
	transferOutOrderInfo,
	transferDetailsInfo,
	transferPdfInfo,
	warehouseInfo,
	warehouseByFilterInfo,
	setCurrentShopUserInfo,
	unsetCurrentShopUserInfo,
	setFavoriteMemberInfo,
	unsetFavoriteMemberInfo,
	transferInOrderConfirmInfo,
	transferOutOrderCreateInfo,
	cashMovementConfirmInfo,
	cashMovementRecordsInfo,
	cashMovementDetailsInfo,
	changeWarehouseInfo,
	giftCameraCouponInfo,
	giftCouponInfo,
	cashCouponInfo,
	reportInfo,
	reportJobInfo,
	reportDetailsInfo,
	reportFilterInfo,
	reportOptionInfo,
	reportExportInfo,
	rftInfoInfo,
	rftInfoDetailsInfo,
	specialOptionsInfo,
	customerAnlysInfo,
	postLogoutInfo,
};
